import React from "react"
import CreateHead from "@components/CreateHead"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Logo from "@components/Logo"
import { setColors } from "@theme/colors"
import { toRem } from "@utils/mixins"
import styled from "@emotion/styled"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
// import useMediaQuery from "@material-ui/core/useMediaQuery"

const Headline = styled.h1`
  font-size: ${toRem(48)};
  font-weight: 400;
  margin: ${toRem(60)} 0 0 0;
  color: ${setColors.grey.darker};
  text-align: center;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Template404 = () => {

   const StyledButton = withStyles(theme => ({
     root: {
       borderRadius: 4,
       border: 0,
       color: "white",
       height: 47,
       boxShadow: "none",
       marginTop: toRem(20),
       marginLeft: "auto",
       marginRight: "auto",
       fontSize: toRem(18),
       [theme.breakpoints.down("xs")]: {
         fontSize: toRem(16),
       },
       background: setColors.blue,
       fontWeight: 500,
       "&:hover": {
         background: setColors.blue,
         color: "#FFF",
         opacity: "0.8",
         boxShadow: "none",
       },
     },
     label: {
       textTransform: "none",
     },
   }))(Button)

   const classes = withStyles()


  return (
    <>
      <CreateHead title={"Welcome to Medtech"} />
      <Logo background={setColors.grey.medium} laptop={182} desktop={182} />
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12}>
            <Headline>Page not found</Headline>
            <Wrapper>
              <StyledButton
                variant="contained"
                href="/"
                size="medium"
                className={classes.button}
              >
                Go to homepage
              </StyledButton>
            </Wrapper>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
export default Template404
